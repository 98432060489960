
"use client"

import { useEffect, useRef } from "react";


const AdSense = ({ adSlot }:{
    adSlot:string
}) => {
const hasRun = useRef(false);

useEffect(() => {
  if (typeof (window) !=='undefined' &&!hasRun.current) {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
    hasRun.current = true;
  }
}, []);

  return (
    <>
    {
     typeof (window) !=='undefined' ? 

       <div className=' my-2  !h-[150px]'>

     <ins
           className="adsbygoogle  !h-[150px] "
           style={{ display: 'flex', width: '100%' ,
            height:"150px",
            alignContent:'center', justifyContent:'center' , }}
           data-ad-client="ca-pub-9084918379047887"
           data-ad-slot="9002229455"

         ></ins>

     </div>
   :null
    }

    </>
  );
};

export default AdSense;